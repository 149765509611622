import React from 'react';
import {
  SOLUTIONS_URL,
  // eslint-disable-next-line
}  from '@env';
import {
  Flex,
  Image,
  Heading,
  Text,
  Button,
  Box,
  ScrollView,
} from 'native-base';
import {
  defaultComponentsColors,
  defaultTextColors,
  handleOpenLink,
} from '../../utilites/utilities';
import SolutionsImage from '../../assets/solutions.png';

const Solutions = () => {
  const handleOpen = async () => {
    await handleOpenLink(SOLUTIONS_URL);
  };

  return (
    <Flex flex={1} flexDirection="column">
      <ScrollView
        px={4}
        _contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 20,
          flexWrap: 'nowrap',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Image
          my={2}
          mx="auto"
          source={SolutionsImage}
          h={[234, 240, 240, 310]}
          w={[350, 350, 350, 450]}
          alt="Imagem app solucoes"
        />
        <Heading
          mt={4}
          color={defaultTextColors.secondary}
          fontSize={['2xl', '2xl', '2xl', '3xl']}
          textAlign={['left', 'left', 'left', 'center']}
        >
          Benefícios para seu negócio, você e sua família
        </Heading>
        <Text
          mt={4}
          fontWeight="medium"
          color="rgba(44, 45, 73, 0.4)"
          fontSize={['md', 'md', 'md', 'lg']}
          textAlign={['left', 'left', 'left', 'center']}
        >
          Descubra que é possível empreender sem deixar de ter acesso a
          benefícios, como plano de saúde, seguro de vida e formação continuada.
        </Text>
      </ScrollView>
      <Box
        left={0}
        right={0}
        bottom={0}
        roundedTop="2xl"
        py={[4, 4, 4, 6]}
        position="absolute"
        bgColor={['#FFF', '#FFF', '#FFF', 'rgba(0, 0, 0, 0)']}
      >
        <Button
          py={2}
          px={2}
          w="2/3"
          mx="auto"
          rounded="full"
          onPress={handleOpen}
          isLoadingText="Carregando posts"
          _text={{ color: '#FFF', fontSize: 'lg' }}
          bgColor={defaultComponentsColors.warning}
        >
          Conhecer soluções
        </Button>
      </Box>
    </Flex>
  );
};

export default Solutions;
